<template>
  <v-card class="shadow rounded-lg">
    <div v-if="loading" class="py-6">
      <loader-content />
    </div>

    <div v-else id="chart">
      <apexchart
        type="bar"
        height="192"
        :options="chartOptions"
        :series="series"
      >
      </apexchart>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data() {
    return {
      loading: true,
      series: [
        {
          name: "Valor em R$",
          data: [0, 0, 0, 0],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        colors: ["#48AB6F", "#48AB6F", "#48AB6F", "#48AB6F"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [
            "Entrada depósitos",
            "Pago em comissionamento",
            "Pago em rentabilidade",
            "Pago em contratos",
          ],
        },
        fill: {
          opacity: 1,
          colors: ["#48AB6F", "#48AB6F", "#48AB6F", "#48AB6F"],
        },
      },
    };
  },

  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    apexchart: VueApexCharts,
  },

  watch: {
    chartData() {
      this.setValue();
    },
  },

  methods: {
    async setValue() {
      this.loading = true;

      this.series[0].data[0] = Number(this.chartData.totalDepositado);
      this.series[0].data[1] = Number(this.chartData.totalPagoComissao);
      this.series[0].data[2] = Number(this.chartData.totalPagoRentabilidade);
      this.series[0].data[3] = Number(this.chartData.totalSacado);

      await new Promise((resolve) => setTimeout(resolve, 250));

      this.loading = false;
    },
  },
};
</script>

<style></style>
