<template>
  <v-container>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans">Dashboard</span>
      </h1>
    </div>

    <section class="mb-8">
      <Analytics />
    </section>

    <section>
      <Transactions />
    </section>
  </v-container>
</template>

<script>
import Analytics from "@/components/home/Analytics";
import Transactions from "@/components/home/Transactions";

export default {
  components: {
    Analytics,
    Transactions,
  },
};
</script>

<style></style>
