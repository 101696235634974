import axios from "axios";

const api = axios.create({
  baseURL: "https://www.binance.com/api/v3",
  timeout: 16000,
});

export const getPrice = data => api.get(`/ticker/price?symbol=${data}`)


