<template>
  <v-dialog v-model="dialog" width="600">
    <v-card v-if="!!deposit">
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Detalhes do depósito</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="mb-4">
          <v-list-item
            v-for="(item, i) in _deposit"
            class="accent rounded-lg mb-2"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="item.mask">
                  <input
                    v-model="item.value"
                    v-mask="item.mask"
                    class="custom-input"
                    readonly
                  />
                </span>

                <span v-else>
                  {{ item.value }}
                </span>
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ item.label }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- proof -->
          <v-list-item class="accent rounded-lg mb-2">
            <v-list-item-content>
              <v-list-item-subtitle
                >Comprovante de pagamento</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                color="primary"
                depressed
                dark
                small
                @click="openReceipt()"
              >
                Visualizar

                <v-icon right> mdi-eye </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <!--status -->
          <v-list-item class="accent rounded-lg mb-2">
            <v-list-item-content>
              <v-list-item-title>Status</v-list-item-title>
              <v-list-item-title>
                <span :class="`${handleStatus(deposit.status).color}--text`">
                  {{ handleStatus(deposit.status).label }}
                </span>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu offset-y @change="handlePrice()">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="white--text"
                    :color="handleStatus(form.status).color"
                    depressed
                    small
                  >
                    {{ handleStatus(form.status).label }}

                    <v-icon right> mdi-chevron-down </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in status"
                    :key="index"
                    @click="form.status = item.value"
                  >
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </div>

        <!-- approve form -->
        <v-form v-model="valid" ref="form">
          <div
            v-show="
              deposit.moeda !== 'BRL' &&
              form.status === '1' &&
              deposit.status === '0'
            "
          >
            <custom-money-input
              v-model="form.value"
              ref="value"
              id="value"
              label="Valor a ser creditado (BRL)"
            />
          </div>
        </v-form>

        <div class="d-flex justify-end">
          <v-btn class="text-none mr-2" plain @click="close()">Cancelar</v-btn>

          <v-btn color="primary" class="text-none" depressed @click="submit()">
            Confirmar
          </v-btn>
        </div>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { getPrice } from "@/services/binance";
import { displayAlert, currencyMask, parseNumber } from "@/utils";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      interval: null,
      valid: false,
      status: [
        {
          value: "1",
          label: "Aprovado",
        },
        {
          value: "-1",
          label: "Recusado",
        },
      ],
      currencyPrice: 0,
      form: {
        value: "0,00",
        status: null,
      },
      deposit: null,
    };
  },

  computed: {
    _deposit() {
      return [
        {
          value: this.deposit.name,
          label: "Cliente",
        },
        {
          value: this.deposit.nomeContrato,
          label: "Produto",
        },
        {
          value: this.deposit.infos,
          label: "Método de pagamento",
        },
        {
          value: `${currencyMask(this.deposit.valor)} ${this.deposit.moeda}`,
          label: "Valor depositado",
        },
      ];
    },
  },

  methods: {
    async submit() {
      if (this.form.status === "1" && !this.$refs.form.validate()) return;

      try {
        this.loading = true;

        let payload = {};

        if (this.form.status === "1") {
          payload = {
            method: "aprovaDeposito",
            depositoID: this.deposit.depositoID,
            depositoID: this.deposit.depositoID,
          };

          if (this.deposit.moeda !== "BRL") {
            payload = {
              ...payload,
              valorReal: parseNumber(this.form.value),
            };
          }
        } else {
          payload = {
            method: "reprovaDeposito",
            depositoID: this.deposit.depositoID,
          };
        }

        await request(payload).then((res) => {
          this.displayAlert(res.message);
          this.$emit("success");

          this.loading = false;
          this.close();
        });
      } catch (err) {
        this.loading = false;
        this.displayAlert(err, 1);
      }
    },

    async getPrice() {
      if (this.deposit.moede === "BRL") return;

      try {
        const payload = this.deposit.moeda + "BRL";

        await getPrice(payload).then((res) => {
          this.currencyPrice = res.data.price;
        });
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },

    handlePrice() {
      const value = currencyMask(this.currencyPrice * this.deposit.valor);

      this.form.value = value;

      try {
        document.getElementById("input").value = value;
      } catch (error) {
        /* ignore */
      }
    },

    handleStatus(value) {
      switch (String(value)) {
        case "0":
          return {
            label: "Aguardando",
            color: "amber",
          };
        case "1":
          return {
            label: "Aprovado",
            color: "green",
          };
        case "-1":
          return {
            label: "Recusado",
            color: "red",
          };
        case "2":
          return {
            label: "Aguardando",
            color: "amber",
          };
        default: {
          return {
            label: value,
            color: "grey",
          };
        }
      }
    },

    openReceipt() {
      const base = !!this.deposit.comprovanteLink
        ? this.deposit.comprovanteLink
        : this.$store.getters.apiURL;
      const url = base + "/" + this.deposit.comprovante;

      window.open(url, "_blank").focus();
    },

    async open(data) {
      this.dialog = true;

      this.form.status = data.status;
      this.deposit = data;

      if (data.moeda !== "BRL" && data.status === "0")
        setTimeout(() => {
          this.$refs.value.handleInput("0,00");
        }, 100);

      if (data.moeda !== "BRL") {
        await this.getPrice();
        this.handlePrice();

        this.interval = setInterval(() => {
          this.getPrice();
        }, 5000);
      }
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();

      if (this.interval !== null) clearInterval(this.interval);
    },

    displayAlert,

    currencyMask,
  },
};
</script>

<style>
.field:focus {
  outline: none !important;
  border: 0px;
}
</style>
