<template>
  <v-card class="shadow rounded-lg" color="backgorund" min-height="405">
    <div class="d-flex align-center px-4 py-2">
      <div class="text-h6 overtext--text mr-2">
        <span class="work-sans"> Rendimentos de Contratos </span>

        <span class="text-body-2 subtext--text">
          <span class="work-sans"> (Total {{ contracts.quantity }}) </span>
        </span>
      </div>

      <v-spacer />

      <v-btn color="primary" depressed rounded text small to="/commissions">
        ver todos
      </v-btn>
    </div>

    <section>
      <v-data-table
        class="transparent"
        :headers="headers"
        :items="contracts.data"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <!-- user -->
        <template v-slot:[`item.name`]="{ item }">
          <router-link :to="`/user/${item.userID}`">
            <v-chip color="primary" link>
              <v-icon left small>mdi-account</v-icon>
              <span>{{ item.name }}</span>
            </v-chip>
          </router-link>
        </template>

        <!-- contract -->
        <template v-slot:[`item.nomeContrato`]="{ item }">
          <router-link :to="`/products/${item.id}`">
            {{ item.nomeContrato }}
          </router-link>
        </template>

        <!-- value -->
        <template v-slot:[`item.value`]="{ item }">
          <div class="d-flex flex-column">
            <span> R$ {{ currencyMask(item.valor) }} </span>
            <span class="text-caption">{{ item["valor%"] }}%</span>
          </div>
        </template>
      </v-data-table>
    </section>
  </v-card>
</template>

<script>
import { currencyMask } from "@/utils";

export default {
  name: "Contracts",

  data() {
    return {
      loading: true,
      manageDialog: false,
      selected: null,
      headers: [
        { text: "Cliente", value: "name" },
        { text: "Produto", value: "nomeContrato" },
        { text: "Descrição", value: "descricao" },
        { text: "Valor", value: "value" },
      ],
    };
  },

  props: {
    contracts: {
      type: Object,
      default: {},
    },
  },

  methods: {
    currencyMask,
  },
};
</script>

<style></style>
