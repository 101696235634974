<template>
  <section>
    <loader-view v-if="loading" />

    <section v-else>
      <v-row>
        <v-col cols="12" md="6">
          <section class="mb-6">
            <Deposits :deposits="deposits" @refresh="getData()" />
          </section>

          <section class="mb-6">
            <Commissions :commissions="commissions" @refresh="getData()" />
          </section>
        </v-col>

        <v-col cols="12" md="6">
          <section class="mb-6">
            <Withdrawals :withdrawals="withdrawals" @refresh="getData()" />
          </section>

          <section class="mb-6">
            <Contracts :contracts="contracts" @refresh="getData()" />
          </section>
        </v-col>
      </v-row>
    </section>
  </section>
</template>


<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";
import Deposits from "./transactions/Deposits.vue";
import Withdrawals from "./transactions/Withdrawals.vue";
import Commissions from "./transactions/Commissions.vue";
import Contracts from "./transactions/Contracts.vue";

export default {
  data() {
    return {
      loading: true,
      deposits: {
        quantity: 0,
        data: [],
      },
      withdrawals: {
        quantity: 0,
        data: [],
      },
      commissions: {
        quantity: 0,
        data: [],
      },
      contracts: {
        quantity: 0,
        data: [],
      },
    };
  },

  components: {
    Deposits,
    Withdrawals,
    Commissions,
    Contracts,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const payload = {
          method: "getallTransactions",
          page: 1,
          ...this.$route.query,
        };

        await request(payload).then(async (res) => {
          this.deposits.quantity = res.totalDepositos;
          this.deposits.data = res.depositos;

          this.withdrawals.quantity = res.totalSaques;
          this.withdrawals.data = res.saques;

          this.commissions.quantity = res.totalUserTransactionsComissoes;
          this.commissions.data = res.userTransactionsComissoes;

          this.contracts.quantity = res.totalUsersContratotransactions;
          this.contracts.data = res.usersContratotransactions;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    openUser(id) {
      this.$router.push({
        path: `/customer/${id}/contracts?callback=dashboard/${this.$route.params.id}`,
      });
    },

    openContract(id) {
      this.$router.push({ path: `/contracts/${id}?callback=dashboard` });
    },

    currencyMask,
  },
};
</script>

<style>
</style>