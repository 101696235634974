<template>
  <v-dialog v-model="dialog" width="600">
    <v-card v-if="!!withdraw">
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Detalhes do saque</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <loader-content v-if="loadingUser" />

        <div class="mb-4" v-else>
          <v-list class="transparent pa-0" nav>
            <v-list-item
              v-for="(item, i) in _withdraw"
              class="accent rounded-lg mb-2"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="item.mask">
                    <input
                      v-model="item.value"
                      v-mask="item.mask"
                      class="custom-input"
                      readonly
                    />
                  </span>

                  <span v-else>
                    {{ item.value }}
                  </span>
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ item.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!--status -->
            <v-list-item class="accent rounded-lg mb-2">
              <v-list-item-content>
                <v-list-item-title> Status da transação</v-list-item-title>
                <v-list-item-title>
                  <span :class="`${handleStatus(withdraw.status).color}--text`">
                    {{ handleStatus(withdraw.status).label }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="white--text"
                      :color="handleStatus(form.status).color"
                      depressed
                      small
                    >
                      {{ handleStatus(form.status).label }}

                      <v-icon right> mdi-chevron-down </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, index) in status"
                      :key="index"
                      @click="form.status = item.value"
                    >
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-form v-show="form.status === '-1'" v-model="valid" ref="form">
            <custom-text-area
              v-model="form.motive"
              ref="motive"
              label="Escreva o motivo"
              placeholder="Motivo da rejeição"
              :required="false"
            />
          </v-form>
        </div>

        <div class="d-flex justify-end">
          <v-btn class="text-none mr-2" plain @click="close()">
            Cancelar
          </v-btn>

          <v-btn color="primary" class="text-none" depressed @click="submit()">
            Confirmar
          </v-btn>
        </div>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { displayAlert, currencyMask } from "@/utils";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      loadingUser: true,
      user: null,
      status: [
        {
          value: "1",
          label: "Aprovado",
        },
        {
          value: "-1",
          label: "Recusado",
        },
      ],
      form: {
        status: null,
        motive: "",
      },
      withdraw: null,
    };
  },

  directives: {
    mask,
  },

  computed: {
    _withdraw() {
      return [
        {
          value: this.withdraw.nome,
          label: "Cliente",
        },
        {
          value: this.user.dadosPessoais.nome_razao,
          label: "Nome ou razão social",
        },
        {
          value: this.withdraw.nomeContrato,
          label: "Produto",
        },
        ...(this.withdraw.tipo === "0"
          ? [
              {
                value: this.withdraw.DadosSaque.banco,
                label: "Banco",
              },
              {
                value: this.withdraw.DadosSaque.agencia,
                label: "Agência",
              },
              {
                value: this.withdraw.DadosSaque.conta,
                label: "Conta",
              },
              {
                value: this.withdraw.DadosSaque.cpfCnpj,
                label: "CPF/CNPJ",
                mask: ["###.###.###-##", "##.###.###/####-##"],
              },
            ]
          : [
              {
                value: this.withdraw.DadosSaque.tipoChave,
                label: "Tipo chave",
              },
              {
                value: this.withdraw.DadosSaque.chave,
                label: "Chave",
              },
            ]),

        {
          value: currencyMask(this.withdraw.valor),
          label: "Valor solicitado",
        },
        {
          value: this.withdraw.DadosSaque.taxas
            ? currencyMask(
                Number(this.withdraw.valor) -
                  Number(this.withdraw.DadosSaque.taxas.taxaSaque)
              )
            : currencyMask(this.withdraw.valor),
          label: "Valor final",
        },
      ];
    },
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload =
          this.form.status === "1"
            ? {
                method: "aprovaSaque",
                saqueID: this.withdraw.saqueId,
              }
            : {
                method: "recusaSaque",
                saqueID: this.withdraw.saqueId,
                motivo: this.form.motive,
              };

        await request(payload).then((res) => {
          this.displayAlert(res.message);
          this.$emit("success");

          this.loading = false;
          this.close();
        });
      } catch (err) {
        this.loading = false;
        this.displayAlert(err, 1);
      }
    },

    async getUser() {
      try {
        const payload = {
          method: "getUserDetail",
          userID: this.withdraw.userID,
        };

        await request(payload).then(async (res) => {
          this.user = res.userDetail;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingUser = false;
      }
    },

    handleStatus(value) {
      switch (String(value)) {
        case "0":
          return {
            label: "Aguardando",
            color: "amber",
          };
        case "1":
          return {
            label: "Aprovado",
            color: "green",
          };
        case "-1":
          return {
            label: "Recusado",
            color: "red",
          };
        default: {
          return {
            label: "Carregando",
            color: "grey",
          };
        }
      }
    },

    async open(data) {
      this.dialog = true;

      this.withdraw = data;
      this.form.status = data.status;

      if (data.status === "-1")
        setTimeout(() => {
          this.$refs.motive.handleInput(data.motive);
        }, 500);

      this.getUser();
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    displayAlert,

    currencyMask,
  },
};
</script>

<style>
.field:focus {
  outline: none !important;
  border: 0px;
}
</style>
