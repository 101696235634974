<template>
  <div class="d-flex align-center justify-center my-2">
    <v-chip-group
      mandatory
      active-class="primary--text"
      show-arrows
      v-model="selected"
    >
      <v-chip v-for="tag in tags" color="surface" :key="tag" label>
        {{ tag }}
      </v-chip>

      <v-dialog ref="dialog" v-model="modal" persistent width="290px">
        <!--input-->
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" color="surface" label>
            Definir Período
            <v-icon class="ml-1">mdi-calendar</v-icon>
          </v-chip>
        </template>

        <!--date pick-->
        <v-date-picker
          v-model="dates"
          v-bind="ruleDate"
          color="primary"
          :max="maxDate"
          scrollable
          no-title
          range
        >
          <v-spacer />

          <v-btn
            color="text_color"
            :disabled="!valid"
            text
            @click="modal = false"
          >
            Ok
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-chip-group>
  </div>
</template>

<script>
const moment = require("moment");

export default {
  data() {
    return {
      valid: false,
      modal: false,
      tags: [
        "Esse Mês",
        "Mês Passado",
        "Essa Semana",
        "Semana Passada",
        "Hoje",
        "Ontem",
        "Tudo",
      ],
      selected: 0,
      dates: [],
      maxDate: moment().format(),
    };
  },

  created() {
    this.startDate(0);
  },

  watch: {
    dates(value) {
      if (value.length > 1) {
        if (value[0] > value[1]) {
          const temp = value[0];

          this.dates.splice(0, 1);
          this.dates.push(temp);
        }
      }
    },

    selected(value) {
      this.startDate(value);
    },
  },

  computed: {
    dateRangeText() {
      return this.dates.join("/");
    },

    ruleDate() {
      this.valid = this.dates.length > 1;
    },
  },

  methods: {
    startDate(value) {
      this.dates = [];

      switch (value) {
        //this month
        case 0:
          this.dates[0] = moment().startOf("month").format("YYYY-MM-DD");
          this.dates[1] = moment().endOf("month").format("YYYY-MM-DD");
          break;

        //last month
        case 1:
          this.dates[0] = moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD");
          this.dates[1] = moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");
          break;

        //this week
        case 2:
          this.dates[0] = moment().startOf("week").format("YYYY-MM-DD");
          this.dates[1] = moment().format("YYYY-MM-DD");
          break;

        //last week
        case 3:
          this.dates[0] = moment()
            .subtract(1, "week")
            .startOf("week")
            .format("YYYY-MM-DD");
          this.dates[1] = moment()
            .subtract(1, "week")
            .endOf("week")
            .format("YYYY-MM-DD");
          break;

        //today
        case 4:
          this.dates[0] = moment().format("YYYY-MM-DD");
          this.dates[1] = moment().format("YYYY-MM-DD");
          break;

        //yesterday
        case 5:
          this.dates[0] = moment().subtract(1, "day").format("YYYY-MM-DD");
          this.dates[1] = moment().subtract(1, "day").format("YYYY-MM-DD");
          break;

        //all
        case 6:
          this.dates[0] = "2000-01-01";
          this.dates[1] = "2049-12-12";
          break;

        //date pick default 3 months
        case 7:
          this.dates[0] = moment().subtract(3, "months").format("YYYY-MM-DD");
          this.dates[1] = moment().format("YYYY-MM-DD");
          break;
      }

      this.$emit("date-pick", this.dates);
    },
  },
};
</script>

<style></style>
