<template>
  <v-card class="shadow rounded-lg" color="backgorund" min-height="405">
    <div class="d-flex align-center px-4 py-2">
      <div class="text-h6 overtext--text mr-2">
        <span class="work-sans">Últimos saques</span>

        <span class="text-body-2 subtext--text">
          <span class="work-sans"> (Total {{ withdrawals.quantity }}) </span>
        </span>
      </div>

      <v-spacer />

      <v-btn color="primary" depressed rounded text small to="/withdrawals">
        ver todos
      </v-btn>
    </div>

    <section>
      <v-data-table
        class="transparent"
        :headers="headers"
        :items="withdrawals.data"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <!-- user -->
        <template v-slot:[`item.name`]="{ item }">
          <router-link :to="`/user/${item.userID}`">
            <v-chip color="primary" link>
              <v-icon left small>mdi-account</v-icon>
              <span>{{ item.nome }}</span>
            </v-chip>
          </router-link>
        </template>

        <!-- value -->
        <template v-slot:[`item.transaction`]="{ item }">
          <div class="d-flex align-center">
            <v-icon class="mr-4" :color="handleStatus(item.status).color" small>
              mdi-circle
            </v-icon>

            <div class="d-flex flex-column">
              <span> {{ currencyMask(item.valor) }} {{ item.moeda }} </span>
              <span class="text-caption">{{ item.nomeContrato }}</span>
            </div>
          </div>
        </template>

        <!-- actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="rounded-lg" icon @click="handleManage(item)">
            <v-icon>mdi-open-in-app</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </section>

    <ManageWithdraw ref="manageWithdraw" @success="$emit('refresh')" />
  </v-card>
</template>

<script>
import ManageWithdraw from "@/components/withdrawals/ManageWithdraw";
import { currencyMask } from "@/utils";

export default {
  name: "Withdrawals",

  data() {
    return {
      loading: true,
      manageDialog: false,
      selected: null,
      headers: [
        { text: "Cliente", value: "name" },
        { text: "Transação", value: "transaction" },
        { text: "Ações", value: "actions", align: "center" },
      ],
    };
  },

  props: {
    withdrawals: {
      type: Object,
      default: {},
    },
  },

  components: {
    ManageWithdraw,
  },

  methods: {
    handleStatus(value) {
      switch (String(value)) {
        case "0":
          return {
            label: "Aguardando",
            color: "amber",
          };
        case "1":
          return {
            label: "Aprovado",
            color: "green",
          };
        case "-1":
          return {
            label: "Recusado",
            color: "red",
          };
        default: {
          return {
            label: "Não encontrado",
            color: "grey",
          };
        }
      }
    },

    handleManage(data) {
      this.$refs.manageWithdraw.open(data);
    },

    openUser(id) {
      this.$router.push({ path: `/customer/${id}?callback=dashboard` });
    },

    currencyMask,
  },
};
</script>

<style></style>
