<template>
  <section>
    <loader-view v-if="loading" />

    <section v-else>
      <FilterPeriod @date-pick="handlePeriod($event)" />

      <v-row>
        <v-col v-for="(item, i) in cards" cols="6" md="3" :key="i">
          <v-card class="shadow rounded-lg">
            <v-list-item>
              <v-list-item-avatar
                class="opacity-primary"
                :size="$vuetify.breakpoint.smAndDown ? 32 : 42"
              >
                <v-icon color="primary" :small="$vuetify.breakpoint.smAndDown">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-if="item.number"
                  class="text-h6 font-weight-regular"
                >
                  {{ item.value }}
                </v-list-item-title>

                <v-list-item-title
                  v-else
                  class="text-body-1 text-md-h6 font-weight-regular"
                >
                  <span class="work-sans">
                    {{ currencyMask(item.value) }}
                  </span>

                  <span class="text-caption">BRL</span>
                </v-list-item-title>

                <v-list-item-subtitle class="text-caption">
                  {{ item.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mb-6">
        <v-col v-for="(item, i) in contracts" :key="i">
          <v-card class="shadow rounded-lg">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="primary--text font-weight-bold">
                  {{ item.contrato }}
                </v-list-item-title>

                <v-list-item-subtitle
                  v-for="(currency, i) in item.depositoMoedas"
                  :key="i"
                >
                  {{
                    currency.totalDeposito
                      ? currencyMask(currency.totalDeposito)
                      : "0,00"
                  }}

                  <span class="text-caption">
                    {{ currency.moeda }} sacados
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <BarChart :chartData="chartData" />
    </section>
  </section>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";
import FilterPeriod from "@/components/filters/FilterPeriod";
import BarChart from "./BarChart";

export default {
  data() {
    return {
      loading: true,
      cards: {
        profitPaid: {
          value: 0,
          label: "Rentabilidade paga",
          icon: "mdi-currency-usd",
        },
        pendingWithdrawals: {
          value: 0,
          label: "Saques pendentes",
          icon: "mdi-currency-usd",
        },
        pendingComissions: {
          value: 0,
          label: "Comissões pendentes",
          icon: "mdi-currency-usd",
        },
        newClients: {
          value: 0,
          label: "Novos clientes",
          icon: "mdi-account-plus",
          number: true,
        },
        inflow: {
          value: 0,
          label: "Total depositado",
          icon: "mdi-arrow-down-bold-box-outline",
        },
        outflow: {
          value: 0,
          label: "Total sacado",
          icon: "mdi-arrow-up-bold-box-outline",
        },
        outflowComission: {
          value: 0,
          label: "Total sacado comissões",
          icon: "mdi-arrow-up-bold-circle-outline",
        },
        outflowContract: {
          value: 0,
          label: "Total sacado contratos",
          icon: "mdi-arrow-up-bold-circle-outline",
        },
      },
      contracts: [],
      range: {
        initialDate: null,
        finalDate: null,
      },
      chartData: null,
    };
  },

  components: {
    FilterPeriod,
    BarChart,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const payload = {
          method: "getAdminDashboard",
          dataInicial: this.range.initialDate,
          dataFinal: this.range.finalDate,
        };

        await request(payload).then(async (res) => {
          this.cards.inflow.value = res.totalDepositado;
          this.cards.profitPaid.value = res.totalPagoRentabilidade;
          this.cards.outflow.value = res.totalSacado;
          this.cards.outflowComission.value = res.totalSacadoComissoes;
          this.cards.outflowContract.value = res.totalSacadoContratos;
          this.cards.pendingWithdrawals.value = res.totalSaqueApagar;
          this.cards.pendingComissions.value = res.totalSaqueComissaoApagar;
          this.cards.newClients.value = res.totalnovosclientes;

          this.contracts = res.totalSacadoData;

          this.chartData = res.grafBarras;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handlePeriod(data) {
      this.range.initialDate = data[0];
      this.range.finalDate = data[1];

      this.getData();
    },

    currencyMask,
  },
};
</script>

<style lang="scss" scoped></style>
